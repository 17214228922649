.btn {
    @include flexProp(13, 16);
    position: relative;
    font-family: 'slaviasemi';
    background-color: transparent;
    color: $c2;
    border: none;
    border: .1rem solid $c6;
    cursor: pointer;
    display: flex;
    padding: 0 2em;
    height: 3.7rem;
    border-radius: 2em;
    align-items: center;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    transition: all .3s;

    &:hover {
        border-color: $c2;
    }

    &--calendar {
        &:after {
            content: '';
            display: inline-block;
            margin-left: 1rem;
            min-width: 1.2rem;
            width: 1.2rem;
            height: 1.3rem;
            min-height: 1.3rem;
            background-image: url(../img/icons/calendar.svg);
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &--dark {
        border: .2rem solid $c2;
        color: $c2;
        height: 4.7rem;
        padding: 0 3em;
        margin-bottom: 1rem;

        &:hover {
            border-color: $c2;
            background-color: $c2;
            color: $c1;
        }
    }
}
