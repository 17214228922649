/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 17, 2017 */

@font-face {
    font-family: 'slaviasemi';
    src: url('../font/slavia-semibold-webfont.woff2') format('woff2'),
         url('../font/slavia-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'slaviabold';
    src: url('../font/slavia-bold-webfont.woff2') format('woff2'),
         url('../font/slavia-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'slavialight';
    src: url('../font/slavia-light-webfont.woff2') format('woff2'),
         url('../font/slavia-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'slaviaregular';
    src: url('../font/slavia-webfont.woff2') format('woff2'),
         url('../font/slavia-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}