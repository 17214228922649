.about {

    
    &__hero {
        @include flexProp(120, 90, 'padding-top');
        @include flexProp(50, 90, 'padding-bottom');
        color: $c1;
        background-size: cover;
        background-position: center;        
    }

    &__heading {
        @include flexProp(35, 56);
        font-family: 'slaviabold';
        line-height: 1.2;
        padding: 0;
    }

    &__photos {
        margin-bottom: 4rem;
    }

    &__bg {
        background-size: cover;
        background-position: center;
        min-height: 60vh;
    }

    &__img {
        margin: 4rem 0;
    }
}