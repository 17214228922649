.gallery {
    background-color: $c5;
    &__info {
        margin-bottom: 2rem;
    }
    &__date {
        padding-bottom: 2rem;
        border-bottom: .1rem solid $c6;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__img {
        margin: 4.5rem 0 2rem;
    }
    &__btn {
        margin-top: 2rem;
    }
    @media(min-width: $sm * 1em) {
        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                margin: 0;
            }
        }
        &__date {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        &__btn {
            margin-top: 0;
        }
    }
}