@mixin flexProp($min, $max, $property: font-size, $start: 320, $end: 1140) {
    $multiplier: ($max - $min) / ($end - $start) * 100;
    $adder: ($min/10 * $end - $max/10 * $start) / ($end - $start);
    $formula:  #{$multiplier + 0vw} + #{$adder + 0rem};
    #{$property}: $min * 0.1rem;
    @media (min-width: ($start * 0.0625em)) {
        #{$property}: calc(#{$formula});
        #{$property}: -webkit-calc(#{$formula});
    }

    @media (min-width: ($end * 0.0625em)) {
        #{$property}: $max * 0.1rem;
    } 
}