.heading {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    &__title {
        margin-bottom: 0;
        line-height: 1;
        margin-bottom: .2em;
    }
    &__wrap {
        @include flexProp(24, 38);
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }
    &__hours {
        &__text {
            line-height: 1;
        }
    }

    @media(min-width: $sm * 1em) {
        flex-direction: row;
        &__wrap {
            // margin-bottom: 0;
        }
    }
}