.header {

    background-color: $c1;

    &__logo {
        display: none;
        z-index: 10;
        position: relative;
        &--mobile {
            display: none;
            width: 18.5rem;
        }
    }

    &__nav-top {
        &__link {
            transition: color .3s;
            &:hover {
                color: $c3
            }
        }
    }

    &__nav-bottom {
        &__link {
            transition: color .3s;
            &:hover {
                color: $c3
            }
        }
    }


    @media(max-width: $headerBreak * 1em) {

        position: fixed;
        width: 100%;
        background-color: $c1;
        z-index: 100;

        &.headroom {
            // will-change: transform;
            transition: transform 200ms linear, background-color 200ms linear;
        }
        &.headroom--pinned {
            transform: translateY(0%);
        }
        &.headroom--unpinned {
            transform: translateY(-100%);
        }
        &.headroom--not-top {
            background-color: $c1;
        }

        &__inner-wrap {
            position: fixed;
            left: 0;
            top: 7rem;
            width: 100%;
            height: 100vh;
            padding: 2rem 4% 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;  
            z-index: 5;
            background-color: $c1;
            align-content: space-between;
            /*overflow: scroll;*/
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s, visibility 0s 0.5s;
            
            &--open {
                opacity: 1;
                visibility: visible;
                transition: opacity 0.5s;
            }
        }

        &__logo {
            &--mobile {
                display: block
            }
        }

        &__container {
            max-width: none;
            display: flex;
            justify-content: space-between;
            height: 7rem;
            align-items: center;
        }

        &__nav-top {
            &__item {
                margin-bottom: 2rem;
            }
            &__link {
                display: block;
                font-size: 2.4rem;
                font-family: 'slaviasemi'
            }
        }

        &__nav-bottom {
            &__list {
                margin-top: 2rem;
                border-top: .1rem solid $c4;
                border-bottom: .1rem solid $c4;
            }
            &__item {
                display: inline-block;
                padding: 1em;
            }
            &__link {
                display: block;
                font-size: 2rem;
                font-family: 'slaviasemi'
            }
        }

        &__hours {
            display: none
        }

    }

    @media(min-width: $headerBreak + 0.0625em) {

        position: fixed;
        width: 20rem;
        z-index: 1000;
        top: 2rem;
        left: 2rem;

        &__logo {
            position: absolute;
            display: block;
            width: 2.3rem;
            margin-right: 3rem;
        }

        &__container {
            padding: 1.5rem;
            padding-top: 2rem;
            display: flex;
        }

        &__inner-wrap {
            width: 100%;
        }

        &__nav-top {
            padding-left: 5rem;
            &__item {
                line-height: 1;
                font-size: 1.6rem;
                &:not(:last-child) {
                    margin-bottom: 1.7rem;                    
                }
            }
            &__link {
                text-decoration: none;
            }
        }

        &__nav-bottom {
            font-size: 1.4rem;
            margin-bottom: .5rem;
            &__list {
                border-top: 0.1rem solid $c4;
                padding-top: .5rem;
                margin-top: 2rem;
                display: flex;
                justify-content: space-between;
            }
            &__link {
                display: inline-block;
                padding: .5em 0;
                text-decoration: none;
            }
        }

        &__hours {
            border-top: .1rem solid $c4;
            display: flex;
            align-items: center;
            padding-top: 2rem;
            padding-bottom: 1rem;

            &:before {
                display: block;
                position: absolute;
                right: 1.5rem;
                content: '';
                width: 3.2rem;
                height: 3.2rem;
                background-image: url(../img/icons/clock.svg);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            &__text,
            &__hours {
                margin: 0;
            }
            &__text {
                font-size: 1.6rem;
                margin: 0;

                &--closed {
                    display: none;
                }
            }
        }

    }
    
    
}