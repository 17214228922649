$container: 70rem;
$headerBreak: 75;

$sm: 35.5;
$md: 46.875;
$lg: 64;

@lost gutter 40px;


$c1: #fff;
$c2: #000;
$c3: #6450ff;
$c4: #edf2f0;
$c5: #f6f6f6;
$c6: #e5e5e5;
$c7: #00ff87;
$c8: #6a58ff;
$c9: #bbb9b3;

