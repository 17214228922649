.hover-info {
    $this: &;
    outline: none;
    display: block;
    position: relative;
    margin-left: 1rem;
    font-family: 'slaviasemi';
    font-size: 1.6rem;
    text-align: center;
    line-height: 2.2rem;
    width: 2.8rem;
    height: 2.8rem;
    border: .3rem solid $c3;
    color: $c3;
    cursor: pointer;    
    border-radius: 50%;
    transition: all .3s;

    &:hover {
        background-color: $c3;
        color: $c4;
    }

    &--dark {
        color: $c2;
        border-color: $c2;

        &:hover {
            background-color: $c2;
            color: $c4;
        }
    }

    &--green {
        color: $c7;
        border-color: $c7;

        &:hover {
            background-color: $c7;
            color: $c9;
        }
    }

    &__content {
        display: none;
        text-align: left;
        position: absolute;
        left: -4rem;
        top: 4rem;
        background-color: $c6;
        padding: 2rem;
        cursor: initial;

        &--open {
            display: block;
        }
    }

    &__heading {
        color: $c3;
        margin: 0;
    }

    &__text {
        color: $c2;
        margin-top: 0 !important;
        white-space: nowrap;
    }

    @media(min-width: $md * 1em) {
        &__content {
            left: 4rem;
            top: 0;
        }
    }
}