.hamburger {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter, top;
    transition-property: opacity, filter, top;
    transition-property: opacity, filter, -webkit-filter, top;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    color: transparent;
    background: transparent !important;
    border: 0;
    margin: 0;
    top: 9px;
    right: 0;
    overflow: visible;
    z-index: 99999;
    height: 33px;
    outline: none;

    @media(min-width: $md * 1em) {
        &:before {
            vertical-align: initial;
            font-size: 2rem;
        }
    }

   @media(min-width: $headerBreak + 0.0625em) {
       display: none;
   }


}

.hamburger-box {
    width: 23px;
    display: inline-block;
    position: relative;
    top: -21px;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 25px;
    height: 2px;
    background: $c3;
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -2px;
}

.hamburger-inner::after {
    bottom: -2px;
}


/*
* Slider
*/

.hamburger--slider .hamburger-inner {
    top: 9px;
}

.hamburger--slider .hamburger-inner::before {
    top: 6px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.2s;
}

.hamburger--slider .hamburger-inner::after {
    top: 12px;
}

.menu-is-open.hamburger--slider .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
    top: 6px;
}

.menu-is-open.hamburger--slider .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
}

.menu-is-open.hamburger--slider .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
    transform: translate3d(0, -16px, 0) rotate(-90deg);
    top: 16px;
}

