.event {
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__img {
        margin-bottom: 2.5rem;
    }
    &__item {
        border-bottom: .1rem solid $c4;
        margin-top: 4rem;
    }
    &__text {
        margin-bottom: 8rem !important;
    }

    &__btn-wrap {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
    }
    @media(min-width: $sm * 1em) {
        &__item {
            lost-column: 1/2;
        }
    }
}