.cafe {

    &__hero {
        background-size: cover;
        background-position: center;
        min-height: 40vh;
    }

    &__photos {
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 3rem;
        }
        &__item {
            width: 100%;
            margin-bottom: 2rem;
        }  
    }

    &__items {
        &__item {
            border-bottom: .1rem solid rgba($c2, .2);
        }
        &__title {
            margin-bottom: 0;
        }
        &__link {
            padding: 1em 0;
            display: block;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:after {
                display: block;
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 1rem;
                background-image: url(../img/icons/arrow.svg);
                background-position: center;
                background-repeat: no-repeat;
                transition: transform .3s;
            }
            &.is-open {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        &__content {
            display: none;
            padding-bottom: 3rem;
        }
    }

    @media(min-width: $sm * 1em) {
        &__photos {
            &__list {
                margin-top: 6rem;
            }
            &__item {
                lost-column: 1/2;
            }            
        }
        &__items {
            &__item {
                lost-column: 1/2;
            }
        }
    }

    @media(min-width: $md * 1em) {
        &__hero {
            min-height: 80vh;
        }
    }
}