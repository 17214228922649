.contact {

    &__heading {
        @include flexProp(60, 81);
        font-family: 'slaviabold';
        padding-bottom: .2em;
    }

    &__subheading {
        @include flexProp(20, 28);
        font-family: 'slaviaregular';
        margin-bottom: 2em;
    }

    &__list {
        &__wrap {
            display: flex;
            flex-direction: column;
            p {
                margin-bottom: .5rem;
                margin-top: .5rem;
            }
        }
        &__title {
            font-family: 'slaviaregular';
            margin-bottom: 0;
        }

        &__item {
            padding-bottom: 3rem;
            border-bottom: .1rem solid $c4;
            &:not(:first-child) {
                margin-top: 2.5rem;
            }
        }
        
        &__contacts {

        }

    }

    @media(min-width: $sm * 1em) {
        &__list {
            &__wrap {
                flex-direction: row;
            }
            &__hours {
                margin-left: auto;
                text-align: right;
            }
        }
    }
}