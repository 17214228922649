.hero {
    background-size: cover;
    background-position: 50% 65%;

    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__heading {
        font-family: 'slaviaregular';
        color: $c1;
        @include flexProp(18, 23);
        line-height: 1.7em;
        letter-spacing: .05em;
    }
}