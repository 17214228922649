.design-shop {
    &__columns {
        margin-top: 3rem;
        font-family: 'slaviasemi';
    }
    &__img {
        margin-bottom: 3rem;
    }
    @media(min-width: $md * 1em) {
        &__columns {
            margin-top: 6rem;
        }
        &__column {
            lost-column: 1/2
        }
    }
}