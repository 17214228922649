html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: 'slaviaregular', Arial, sans-serif;
    line-height: 1.45;
    color: $c2;
    &.no-scroll {
        overflow: hidden;
    }
}

* {
    box-sizing: border-box;
}

.container {
    margin: 0 auto;
    max-width: calc(#{$container} + 4rem);
    padding: 0 2rem;
    position: relative;
    width: 100%;
    
    @media(min-width: $md * 1em) {
        max-width: calc(#{$container} + 8%);
        padding: 0 4%;
    }
}

a {
    color: currentColor;
}

a:not([class]) {
    color: currentColor;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}



img {
    display: block; 
    max-width: 100%;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.clear {
    clear: both;
}

.visually-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

h1,
h2,
h3 {
    font-family: 'slaviasemi';
    margin: 0;
}

h1 {
    @include flexProp(25, 45);
    padding-bottom: 1.3em;
}

.h1 {
    @include flexProp(28, 48);
    font-weight: 600;
    line-height: 1;
}

h2 {
    @include flexProp(24, 38);
    margin-bottom: .4em;
}

h3,
.h3 {
    @include flexProp(16, 20);
    margin-bottom: .8em;
    font-family: 'slaviasemi'
}

p {
    &:first-of-type {
        margin-top: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul:not([class]) {
    list-style-type: disc;
    padding-left: 1em;
}

.absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

img[data-sizes="auto"] { display: block; width: 100%; }

.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

.section-padding {
    @include flexProp(50, 90, 'padding-top');
    @include flexProp(50, 90, 'padding-bottom');
}


.four-o-four {
    color: $c1;
    h1 {
        padding: 0;
    }
}
