.footer {
    background-color: $c8;
    &__title {
        font-size: 2rem;
    }
    &__heading {
        @include flexProp(30, 40);
        font-family: 'slaviabold';
        line-height: 1.2;
        position: relative;
        padding-right: 4rem;
        &:not(:last-child) {
            &:after {
                content: '';
                width: 1rem;
                height: 1rem;
                background-color: $c2;
                border-radius: 50%;
                position: absolute;
                right: 1.5rem;
                top: calc(50% - .5rem);
            }
        }
        &--sm {
            margin-right: 0;
        }
    }
    &__row {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        margin-bottom: 2rem;
    }
    &__columns {
    }
    &__column {
        margin-top: 3rem;
        p {
            margin: 0;
            font-family: 'slaviasemi'
        }
    }

    &__text {
        margin-top: 3rem;
        font-size: 1.4rem;
    }

    @media(min-width: $md * 1em) {
        &__column {
            lost-column: 1/2;
        }
    }
}

