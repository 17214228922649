.bottega {
    position: relative;
    &:after {
        content: '';
        background: $c1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: .5;
        z-index: 0;
    }
    &__container {
        position: relative;
        z-index: 1;
    }
    &__list {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: 100%;
        font-family: 'slaviasemi';
        font-size: 1.8rem;
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: .1rem solid rgba($c2, .2);

        a {
            text-transform: uppercase;
            text-decoration: none;
            &:hover {
                text-decoration: underline
            }
        }
    }

    &__body {
        margin-bottom: 3rem;
    }

    @media(min-width: $sm * 1em) {
        &__item {
            lost-column: 1/2;
        }
    }

    @media(min-width: $md * 1em) {
        &__item {
            lost-column: 1/3;
            &:nth-last-child(-n+3) {
                border-bottom: none;
            }
        }
        &__body {
            margin-bottom: 6rem;
        }
    }
}